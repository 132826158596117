import React, { useState, useContext, useEffect } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";

import logo from "../../assets/logos/Aura-Azul.svg";
import "../../assets/scss/pages/_login.scss";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ErrorContext, ErrorContextType } from "../../context/ErrorContext";
import axios, { AxiosError } from "axios";

function LoginPage() {
  const navigate = useNavigate();
  const { isAuthenticated, login, logout } = useAuth();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    remember: false,
  });


  const handleInputChange = (field: string, value: string) => {
    setLoginData({ ...loginData, [field]: value });
  };

  const { showErrorModal } = useContext<ErrorContextType>(ErrorContext);

  const handleLogin = async () => {
    try {
      await logout(navigate);
      await login(loginData, navigate);
      navigate("/home");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        showErrorModal(
          "Error en login",
          "Hubo un error durante el inicio de sesión: " +
            error?.response?.data?.message,
          "error"
        );
        console.error("Error de inicio de sesión:", error);
      }
    }
  };

  if (isAuthenticated()) {
    navigate("/home");
  }

  return (
    <div className="login-page">
      <div>
        <div className="login-div">
          <div className="centerText">
            <img src={logo} alt="Home" />
          </div>
          <div className="centerText">
            <h1>Ingresar a mi cuenta</h1>
          </div>
          <Input
            label="Email"
            value={loginData.email}
            placeholder="Introducir email"
            onChange={(value) => handleInputChange("email", value)}
          />
          <Input
            label="Contraseña"
            value={loginData.password}
            placeholder="••••••••"
            type="password"
            onChange={(value) => handleInputChange("password", value)}
          />

          <div className="links-container">
            <Checkbox
              checked={loginData.remember}
              onChecked={(checked) => {
                setLoginData({ ...loginData, remember: checked });
              }}
              label="Recordarme"
            />

            {/*
            <Link className="link" to="/recuperar-contrasena">
              Olvidé mi contraseña
            </Link>
             */}
          </div>

          <Button color="primary" onClick={handleLogin}>
            Ingresar
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;