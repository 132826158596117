import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import CloseIcon from '../../../assets/icons/close.svg';
import ParquesIcon from '../../../assets/icons/parques-grey.svg';
import CirclePlayIcon from '../../../assets/icons/circle-play-grey.svg';
import CircleDownloadIcon from '../../../assets/icons/circle-download-grey.svg';
import UsersIcon from '../../../assets/icons/users-grey.svg';
import ParquesIconRed from '../../../assets/icons/parques.svg';
import CirclePlayIconRed from '../../../assets/icons/circle-play.svg';
import CircleDownloadIconRed from '../../../assets/icons/circle-download.svg';
import UsersIconRed from '../../../assets/icons/users.svg';
import PoweredBaner from '../../../assets/logos/poweredBaner.png';
import { useAuth } from '../../../context/AuthContext';

interface MenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const Menu: React.FC<MenuProps> = ({ isOpen, onClose }) => {
  const location = useLocation();
  const { user } = useAuth();

  return (
    <>
    {isOpen && (
      <div className="overlay" onClick={onClose}></div>
    )}
    <div className={`menu ${isOpen ? 'open' : ''}`}>
      <div>
        <p>Menu</p>
        <div onClick={onClose}>
          <img src={CloseIcon} alt="Cerrar Menú" />
        </div>
      </div>
      <ul>

        {(user && user.permisos.some((permiso: any) => permiso.nombre === 'app-parques')) && (
          <li className={location.pathname === '/parques' ? 'active' : ''}>
            <Link to="/parques" state={{ from: 'Menu' }}>
              <img src={ParquesIcon} alt="Parques" /><img src={ParquesIconRed} alt="Parques" /> Parques
            </Link>
          </li>
        )}

        {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-gestion-envios')) && (
          <li className={location.pathname === '/gestion-envios' ? 'active' : ''}>
            <Link to="/gestion-envios" state={{ from: 'Menu' }}>
              <img src={CirclePlayIcon} alt="Gestión de envíos" /><img src={CirclePlayIconRed} alt="Gestión de envíos" /> Gestión de envíos
            </Link>
          </li>
        )}

        {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-descarga-ficheros')) && (
          <li className={location.pathname === '/descarga-ficheros' ? 'active' : ''}>
            <Link to="/descarga-ficheros" state={{ from: 'Menu' }}>
              <img src={CircleDownloadIcon} alt="Descarga de ficheros" /><img src={CircleDownloadIconRed} alt="Descarga de ficheros" /> Descarga de ficheros
            </Link>
          </li>
        )}

        {(user && user.permisos.some((permiso: any) => permiso.nombre === 'view-users')) && (
          <li className={location.pathname === '/gestion-usuarios' ? 'active' : ''}>
            <Link to="/gestion-usuarios/usuarios" state={{ from: 'Menu' }}>
              <img src={UsersIcon} alt="Gestión de usuarios" /><img src={UsersIconRed} alt="Gestión de usuarios" /> Gestión de usuarios
            </Link>
          </li>
        )}

      </ul>
    </div>
    </>
  );
};

export default Menu;