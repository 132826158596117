import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import "../../assets/scss/pages/_home.scss";
import Navbar from "../../components/Navbar";
import logo from "../../assets/logos/Aura-Blanco-home.svg";
import ParquesIcon from "../../assets/icons/parques.svg";
import CirclePlayIcon from "../../assets/icons/circle-play.svg";
import CircleDownloadIcon from "../../assets/icons/circle-download.svg";
import UsersIcon from "../../assets/icons/users.svg";
import ParquesIconHover from "../../assets/icons/parques.svg";
import CirclePlayIconHover from "../../assets/icons/circle-play.svg";
import CircleDownloadIconHover from "../../assets/icons/circle-download.svg";
import UsersIconHover from "../../assets/icons/users.svg";
import { useAuth } from "../../context/AuthContext";

function HomePage() {
  const { user, isAuthenticated } = useAuth();
  
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="home-page">
      <div className="cabecera">
        <Navbar />
        <div className="main-image">
          <div>
            <img className="logo-img2" src={logo} alt="Home" />
          </div>
        </div>
      </div>
      <div className="main-content">
        <h2>Módulos</h2>
        <div className="module-container">
          {user &&
            user.permisos.some(
              (permiso: any) => permiso.nombre === "app-parques"
            ) && (
              <Link to="/parques" state={{ from: "Home" }} className="parques">
                <img src={ParquesIcon} alt="Parques" />
                <img src={ParquesIconHover} alt="Parques" /> Parques
              </Link>
            )}

          {user &&
            user.permisos.some(
              (permiso: any) => permiso.nombre === "view-gestion-envios"
            ) && (
              <Link
                to="/gestion-envios"
                state={{ from: "Home" }}
                className="envios"
              >
                <img src={CirclePlayIcon} alt="Gestión de envíos" />
                <img src={CirclePlayIconHover} alt="Gestión de envíos" />{" "}
                Gestión de envíos
              </Link>
            )}

          {user &&
            user.permisos.some(
              (permiso: any) => permiso.nombre === "view-descarga-ficheros"
            ) && (
              <Link
                to="/descarga-ficheros"
                state={{ from: "Home" }}
                className="descarga"
              >
                <img src={CircleDownloadIcon} alt="Descarga de ficheros" />
                <img
                  src={CircleDownloadIconHover}
                  alt="Descarga de ficheros"
                />{" "}
                Descarga de ficheros
              </Link>
            )}

          {user &&
            user.permisos.some(
              (permiso: any) => permiso.nombre === "view-users"
            ) && (
              <Link
                to="/gestion-usuarios/usuarios"
                state={{ from: "Home" }}
                className="usuarios"
              >
                <img src={UsersIcon} alt="Gestión de usuarios" />
                <img src={UsersIconHover} alt="Gestión de usuarios" /> Gestión
                de usuarios
              </Link>
            )}
        </div>
      </div>
    </div>
  );
}

export default HomePage;